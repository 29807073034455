import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="https://reactjs.org"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Learn React*/}
        {/*</a>*/}
        {/*<a rel="ar" href="/assets/models/LemonMeringuePie.usdz">*/}
        {/*<a rel="ar" href="assets/models/LemonMeringuePie.usdz">*/}
        <a rel="ar" href="https://drive.google.com/uc?export=download&id=14Dt4haQWHv94jypRaajKdDuSmb2Av7kY">
          <div>i am a fat rabbit</div>
          {/*<img src={require("./assets/models/meringue_2x.jpg")}/>*/}
        </a>
      </header>
    </div>
  );
}

export default App;
